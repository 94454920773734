module.exports = {
    /**
     * @description 请求超时时间，毫秒（默认2分钟）
     */
    timeout: 1200000,
    /**
     * 请求接口地址
     */
    // requestUrl: 'http://docs.wegenet.com/',
    requestUrl: 'https://www.accountconnect.xyz/',
    appRequestUrl: 'https://m.wegenet.com/', // 开户易app接口地址
    adminRequestUrl: 'https://m.wegenet.com/', // 开户易管理系统接口地址
    httpUrl: 'https://m.wegenet.com',
}
