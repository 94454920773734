import { Axios } from '@/utils/request'
export default class abouten extends Axios {
  aboutMyEN() { //关于我们
    return this.request({
      method: "get",
      url: 'api.php/about/34/acode/en',
    })
  }
  bannerMyEN() { //关于我们首页图
    return this.request({
      method: "get",
      url: 'api.php/cms/sort/scode/34/acode/en',
      
    })
  }
  linkgid() { //入住机构
    return this.request({
      method: "get",
      url: 'api.php/cms/link/gid/1/num/10',
    })
  }
  cmssortImgEN() { //开户活动
    return this.request({
      method: "get",
      url: 'api.php/cms/sort/scode/35/acode/en',
    })
  }
  aboutTextEN() { //活动宗旨
    return this.request({
      method: "get",
      url: 'api.php/about/36/acode/en',
    })
  }
  // -最新活動-過往活動（get提交）
  pagelistnumEN() { 
    return this.request({
      method: "get",
      url: 'api.php/list/37/page/1/num/3/acode/en',
    })
  }

//过往活动详情
contentadisetEN(data) {
  return this.request({
      method: "get",
      url: `api.php/content/${data}/acode/en`,
  })
}
//開戶指南banner
sortscodeacodebannerEN() {
  return this.request({
      method: "get",
      url: 'api.php/cms/sort/scode/38/acode/en',
  })
}
//開戶指南文本
userTextEN() {
  return this.request({
      method: "get",
      url: 'api.php/about/39/acode/en',
  })
}
//AML工作指南
aboutAmlEN() {
  return this.request({
      method: "get",
      url: 'api.php/about/40/acode/en',
  })
}
//机构白名单
cmsscodeacodeEN() {
  return this.request({
      method: "get",
      url: 'api.php/cms/sort/scode/41/acode/en',
  })
}
//加入我们
aboutMYEN() {
  return this.request({
      method: "get",
      url: 'api.php/about/41/acode/en',
  })
}
//联络我们banner
cmsbannerEN() {
  return this.request({
      method: "get",
      url: 'api.php/cms/sort/scode/42/acode/en',
  })
}
//联络我们form
aboutacodeTextEN() {
  return this.request({
      method: "get",
      url: 'api.php/about/42/acode/en',
  })
}
//在线留言
cmsaddmsgaEN(data) {
  return this.request({
      method: "POST",
      url: 'api.php/cms/addmsg/acode/en',
      data
  })
}
}