// lang / zh.ts
export default {
  nav: {
    about: '私たちについて',
    AccountOpenAct: '口座開設活動',
    AccountOpenGuide: '口座開設ガイド',
    whiteList: 'パートナーの入居',
    contactUs: '連絡先',
    download: 'ダウンロード',
    accountOpenInfo: '口座情報',
    technologyPlatform: '科学技術プラットフォーム',
  },
  common: {
    email: 'メール',
    enter: '入力してください...',
    pleaseSelect: '選択してください...',
    confirm: '確認',
    enterContactInfo: '連絡先を入力してください',
    seeMore: '詳細を表示',
    currentLocation: '現在位置',
    back: '戻る',
    query: '検索',
    chat: '会話',
    uploadFiles: 'ファイルをアップロード',
    support: 'サポート',
    fileTypes: 'ファイルタイプ',
    notSupported: 'このファイルタイプはサポートされていません',
    limit: '制限数',
    canUploaded: '個',
    reset: 'リセット'
  },
  about: {
    bannerTitle: '口座を開設するにはユニバーサルビジネスを利用する',
    userLogin: '専門機関ユーザーのログイン',
    progressQuery: '口座開設進捗照会',
    pairedSingle: 'ペアリング済み（シングル）',
    institution: '登録専門機関（家）',
    traditionalBanks: '在来銀行（ホーム）',
    digitalBanks: 'デジタルバンキング',
    residentInstitutions: '駐在機構',
    pairableBank: 'マッチング可能な銀行（家）',
  },
  accountOpenAct: {
    purpose: '活動目的',
    latest: '最新アクティビティ',
    past: '過去の活動',
    actDetails: 'アクティビティの詳細',
    organizer: '主催者',
    activityTime: 'アクティビティ時間',
  },
  accountOpenGuide: {
    companyProcess: '企業口座開設プロセス',
    companyProcessOne: '提携交渉',
    companyProcessTwo: '専門家による事前審査',
    companyProcessThree: '銀行再審',
    companyProcessFour: '口座を予約する',
    companyProcessFive: '口座開設に成功しました',
    personalProcess: '個人口座開設プロセス',
    personalProcessOne: 'ユーザーが処理を確定した後、銀行届出情報表を事前に準備する（この表は重要）',
    personalProcessTwo: '関連データを海外銀行に提出する',
    personalProcessThree: 'ユーザーが外国口座に送金するために必要なアクティベーション金',
    personalProcessFour: '銀行口座振替、ネットバンキングIDパスワード（セキュリティのためにユーザーがログインしてから最短時間で自分でパスワードを変更）、銀行カード',
    personalProcessFive: 'ネットバンクの検索機能を開通',
    amlWorkGuide: 'AMLワークガイド'
  },
  download: {
    title: 'ユニバーサルビジネスフォン',
    queryDesc: '注文サービスが便利で、問い合わせの進行が便利',
    version: '開発者：WeGeNet International (HK) Limited バージョン：1.2.0(39)プライバシーポリシー応用権限',
    iosDownload: 'IOSダウンロード',
    androidDownload: 'Androidダウンロード',
    accountOpenProgress: '口座開設の進捗',
    companyList: '会社リスト',
    orderNo: 'オーダー番号',
    orderTips: '詳細な注文情報を表示するには、APPを開くか、APPをダウンロードして表示してください',
    companyTips: '会社を選択してください',
  },
  institution: {
    bannerTitle: '専門機関が大募集中',
    joinUs: '私たちに参加して',
    settleIn: '入居プロセス',
    immediateEntry: '即時入居',
    joinListOne: '顧客ニーズ指向性ドッキング',
    joinListTwo: 'コアコンピテンシーの優先使用',
    joinListThree: 'コアパートナー戦略投資',
    joinListFour: 'サービス着地専用サポート',
    processListOne: '入居資料の提出',
    processListTwo: '資格審査',
    processListThree: 'ビジネス契約完了',
    processListFour: 'プラットフォーム機構情報の充実',
    processListFive: 'サービスリリースのオンライン化',
    processListOneDesc: '機関資料を提出して専門機関のアカウントを登録する',
    processListTwoDesc: 'クラス運用レビュー（7営業日以内）',
    processListThreeDesc: 'オフライン契約を締結し、保証金を納付する',
    processListFourDesc: 'プラットフォーム機構情報を完備し、顧客情報資料を追加する',
    processListFiveDesc: 'サービスは正式にオンラインで顧客サービスを受けている',
  },
  contact: {
    tips: '何か問題があれば、私たちに連絡してください。連絡先を残してください。できるだけ早く返事します。',
    contactTips: '（唯一の公式連絡先で、Facebook、WeChat、その他のソーシャルメディア連絡先はありません）',
    support: 'カスタマーサポート',
    supportTips: 'いつでもお手伝いします！問い合わせがあれば、メールで',
    supportContact: 'customercare@wegenet.com',
    query: '一般的なクエリー',
    queryTips: 'パートナーシップを含む一般的なクエリの場合は、このページのテーブルに記入してください。',
    queryContact: 'WhatsApp：+852 5122 5622',
    companyAddr: '会社住所',
    addr: 'アドレス',
    name: 'の名前をあげる',
    phoneNum: '連絡番号',
    companyName: '会社名',
    position: 'ポジション',
    subject: 'タイトル',
    remark: 'メッセージ',
    submit: '送信',
  },
  login: {
    login: 'ログイン＃ログイン＃',
    logOut: 'ログオンの終了',
    loggedAccount: 'ログイン済みアカウント',
    accountLogin: 'アカウントパスワードログイン',
    accountTips: 'アカウントを入力してください',
    pwdTips: 'パスワードを入力してください',
  },
  rpData: {
    company: '会社',
    person: '個人',
    title: 'RP 入驻資料の入力',
    desc: '情報を正しく入力し、プラットフォームの審査に役立て下さい',
    basicInfo: '基本情報',
    settlementType: '入駐タイプ',
    settlementTypePlaceholder: 'お選び下さい',
    supportingHKCompanies: '現在、香港会社のみの入駐をサポートしています',
    companyNameEn: '御社の英語名',
    companyNameEnPlaceholder: '御社の英語名を入力してください',
    companyNameCn: '御社の中国語名',
    companyNameCnPlaceholder: '御社の中国語名を入力してください',
    companyWebsite: '御社のウェブサイト',
    companyWebsitePlaceholder: '御社のウェブサイトを入力してください',
    fax: 'ファックス',
    faxPlaceholder: 'ファックス番号を入力してください',
    contactNameEn: '連絡人の英語名',
    contactNameEnPlaceholder: '連絡人の英語名を入力してください',
    contactNameCn: '連絡人の中国語名',
    contactNameCnPlaceholder: '連絡人の中国語名を入力してください',
    phoneNum: '連絡番号',
    phoneNumPlaceholder: '連絡番号を入力してください',
    phoneNumAreaCodePlaceholder: '連絡先電話番号を選択してください',
    email: 'メールアドレス',
    emailPlaceholder: 'メールアドレスを入力してください',
    addr: '御社の住所',
    addrPlaceholder: '住所をご記入ください',
    roomNo: '階/部屋番号',
    street: '通り名',
    areaName: '地域名',
    countryRegion: '国/地域',
    certificateReg: '会社登記証書',
    businessRegCertificate: '商業登記証書',
    AnnualDeclarationForm: '1年ぶりの申告書(最新年度)',
    articlesAssociation: '会社規程'
  }
};