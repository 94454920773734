import { reactive, ref } from "vue";
import Settings from '@/utils/settings'
import about from '@/api/about'
import aboutTw from '@/api/aboutTw'
import abouten from '@/api/abouten'
import aboutenjs from '@/api/aboutJa'
const apiaboutTw = new aboutTw()
const apiAbout = new about()
const apiaboutenja = new aboutenjs()
const apiabouten = new abouten()
const requestUrl = Settings.requestUrl

const aboutbrief = ref()//关于我们简介
const bannerImg = ref()//首页图
const linkgidImg = ref()//商鲸图
const cmssortImg = ref()//开户banner
const aboutText = ref()//开户活动
let list = ref()
const userTextZN = reactive({
    TextZN: '',
    TextZNImg: '',
    TextZNZML: '',
    AMLZNImg: ''
})
const institution = reactive({
    cmsscodeacodeImg: '',
    abouTextMY: '',
    linkgidImg: ''
})
const userTextBannerZN = ref()
const icmsbannerZNImg = ref()
const liaisonText = ref()
/*****************中*******************/
function znrequest() {//中
    apiAbout.aboutMy().then(res => { //简介
        aboutbrief.value = res.data.data.content
    }),
        apiAbout.bannerMy().then(res => { //首页图
            bannerImg.value = requestUrl + res.data.data.pic
        })
    apiAbout.linkgid().then(res => {//商鲸图
        linkgidImg.value = requestUrl + res.data.data[0].logo
        institution.linkgidImg = requestUrl + res.data.data[0].logo
    })


    apiAbout.cmssortImg().then(res => { //开户banner
        cmssortImg.value = requestUrl + res.data.data.pic
    })
    apiAbout.aboutText().then(res => { //开户活动 活动宗旨
        aboutText.value = res.data.data.content
    })

    apiAbout.pagelistnum().then(res => {
        let {data ,msg, code} = res
        if(code == 0 && data.data.length){
            data.data.forEach(item => {
                item.ico = requestUrl + item.ico
            });
            list.value = JSON.parse(JSON.stringify(data.data))
        }
    })

    apiAbout.sortscodeacodebannerZN().then(res => {//开户banner
        userTextBannerZN.value = requestUrl + res.data.data.pic
    })
    apiAbout.userTextZN().then(res => { //开户指南
        userTextZN.TextZN = res.data.data
        userTextZN.TextZNImg = requestUrl + res.data.data.pics
    })

    apiAbout.aboutAmlZN().then(res => {
        userTextZN.TextZNZML = res.data.data
        userTextZN.AMLZNImg = requestUrl + res.data.data.pics
    })
    //机构白名单
    apiAbout.cmsscodeacodeZN().then(res => {
        institution.cmsscodeacodeImg = requestUrl + res.data.data.pic
    })
    apiAbout.aboutMYZN().then(res => { //加入我们
        institution.abouTextMY = res.data.data.content
    })
    // 联络我们banner
    apiAbout.cmsbannerZN().then(res => {
        icmsbannerZNImg.value = requestUrl + res.data.data.pic
    })
    //联络我们
    apiAbout.aboutacodeTextZN().then(res => {
        liaisonText.value = res.data.data.content
    })
}

/*****************台**********************************************************/
function Twrequest() {
    apiaboutTw.aboutacodeTextTw().then(res => { //简介
        aboutbrief.value = res.data.data.content
    }),
        apiaboutTw.cmssortscodeBannerTw().then(res => { //首页图
            bannerImg.value = requestUrl + res.data.data.pic
        })
    apiAbout.linkgid().then(res => {//商鲸图
        linkgidImg.value = requestUrl + res.data.data[0].logo
        institution.linkgidImg = requestUrl + res.data.data[0].logo
    })
    apiaboutTw.cmssortscodeTw().then(res => { //开户banner
        cmssortImg.value = requestUrl + res.data.data.pic
    })
    apiaboutTw.aboutTextTw().then(res => { //开户活动 活动宗旨
        aboutText.value = res.data.data.content
    })
    apiaboutTw.listpageacodeTw().then(res => { //最新活动
        console.log(res)
        let {data ,msg, code} = res
        if(code == 0 && data.data.length){
            data.data.forEach(item => {
                item.ico = requestUrl + item.ico
            });
            list.value = JSON.parse(JSON.stringify(data.data))
        }
    })
    //开户指南banner
    apiaboutTw.sortscodeacodebannerTw().then(res => {
        userTextBannerZN.value = requestUrl + res.data.data.pic
    })
    apiaboutTw.aboutacodeTw().then(res => { //开户指南文本
        userTextZN.TextZN = res.data.data
        userTextZN.TextZNImg = requestUrl + res.data.data.pics
    })
    apiaboutTw.aboutacodeAmlTw().then(res => {//AML
        userTextZN.TextZNZML = res.data.data
        userTextZN.AMLZNImg = requestUrl + res.data.data.pics
    })
    apiaboutTw.cmsscodeacodehkTw().then(res => {//机构白名单
        institution.cmsscodeacodeImg = requestUrl + res.data.data.pic
    })
    apiaboutTw.aboutacodehkTw().then(res => { //加入我们
        institution.abouTextMY = res.data.data.content
    })
    apiaboutTw.cmsbannerTw().then(res => { //联络我们banner
        icmsbannerZNImg.value = requestUrl + res.data.data.pic
    })
    //联络我们
    apiaboutTw.aboutacodeTextTW().then(res => {
        liaisonText.value = res.data.data.content
    })
}
/*********************英文********************/
function enrequest() {
    apiabouten.aboutMyEN().then(res => { //简介
        aboutbrief.value = res.data.data.content
    }),
        apiabouten.bannerMyEN().then(res => { //首页图
            bannerImg.value = requestUrl + res.data.data.pic
        })
    apiabouten.linkgid().then(res => {//商鲸图
        linkgidImg.value = requestUrl + res.data.data[0].logo
        institution.linkgidImg = requestUrl + res.data.data[0].logo
    })


    apiabouten.cmssortImgEN().then(res => { //开户banner
        cmssortImg.value = requestUrl + res.data.data.pic
    })
    apiabouten.aboutTextEN().then(res => { //开户活动 活动宗旨
        aboutText.value = res.data.data.content
    })

    apiabouten.pagelistnumEN().then(res => {
        let {data ,msg, code} = res
        if(code == 0 && data.data.length){
            data.data.forEach(item => {
                item.ico = requestUrl + item.ico
            });
            list.value = JSON.parse(JSON.stringify(data.data))
        }
    })

    apiabouten.sortscodeacodebannerEN().then(res => {//开户banner
        userTextBannerZN.value = requestUrl + res.data.data.pic
    })
    apiabouten.userTextEN().then(res => { //开户指南
        userTextZN.TextZN = res.data.data
        userTextZN.TextZNImg = requestUrl + res.data.data.pics
    })

    apiabouten.aboutAmlEN().then(res => {
        userTextZN.TextZNZML = res.data.data
        userTextZN.AMLZNImg = requestUrl + res.data.data.pics
    })
    //机构白名单
    apiabouten.cmsscodeacodeEN().then(res => {
        institution.cmsscodeacodeImg = requestUrl + res.data.data.pic
    })
    apiabouten.aboutMYEN().then(res => { //加入我们
        institution.abouTextMY = res.data.data.content
    })
    // 联络我们banner
    apiabouten.cmsbannerEN().then(res => {
        icmsbannerZNImg.value = requestUrl + res.data.data.pic
    })
    //联络我们
    apiabouten.aboutacodeTextEN().then(res => {
        liaisonText.value = res.data.data.content
    })
}
/*********************日***********************/
function Jarequest() {
    apiaboutenja.aboutMyJA().then(res => { //简介
        aboutbrief.value = res.data.data.content
    }),
    apiaboutenja.bannerMyJA().then(res => { //首页图
            bannerImg.value = requestUrl + res.data.data.pic
        })
    apiaboutenja.linkgid().then(res => {//商鲸图
        linkgidImg.value = requestUrl + res.data.data[0].logo
        institution.linkgidImg = requestUrl + res.data.data[0].logo
    })
    apiaboutenja.cmssortImgJA().then(res => { //开户banner
        cmssortImg.value = requestUrl + res.data.data.pic
    })
    apiaboutenja.aboutTextJA().then(res => { //开户活动 活动宗旨
        aboutText.value = res.data.data.content
    })

    apiaboutenja.pagelistnumJA().then(res => {
        let {data ,msg, code} = res
        if(code == 0 && data.data.length){
            data.data.forEach(item => {
                item.ico = requestUrl + item.ico
            });
            list.value = JSON.parse(JSON.stringify(data.data))
        }
    })

    apiaboutenja.sortscodeacodebannerJA().then(res => {//开户banner
        userTextBannerZN.value = requestUrl + res.data.data.pic
    })
    apiaboutenja.userTextJA().then(res => { //开户指南
        userTextZN.TextZN = res.data.data
        userTextZN.TextZNImg = requestUrl + res.data.data.pics
    })

    apiaboutenja.aboutAmlJA().then(res => {
        userTextZN.TextZNZML = res.data.data
        userTextZN.AMLZNImg = requestUrl + res.data.data.pics
    })
    //机构白名单
    apiaboutenja.cmsscodeacodeJA().then(res => {
        institution.cmsscodeacodeImg = requestUrl + res.data.data.pic
    })
    apiaboutenja.aboutMYJA().then(res => { //加入我们
        institution.abouTextMY = res.data.data.content
    })
    // 联络我们banner
    apiaboutenja.cmsbannerJA().then(res => {
        icmsbannerZNImg.value = requestUrl + res.data.data.pic
    })
    //联络我们
    apiaboutenja.aboutacodeTextJA().then(res => {
        liaisonText.value = res.data.data.content
    })
}


// znrequest()//中
// Twrequest()//台
export {
    znrequest,
    Twrequest,
    enrequest,
    Jarequest,
    aboutbrief,
    bannerImg,
    linkgidImg,
    cmssortImg,
    aboutText,
    list,
    userTextZN,
    institution,
    icmsbannerZNImg,
    liaisonText,
    userTextBannerZN
}