<template>
    <el-dialog
    v-model="dialogVisible"
    title="Warning"
    width="400px"
    align-center
    class="login"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    @close="closeDialog"
    >
        <template #header="{ close, titleId, titleClass }">
        <div class="header">{{ $t('login.accountLogin') }}</div>
        </template>
        <div class="box">
            <div class="items">
                <span>WEGE-</span>
                <!-- <el-input v-model="input" placeholder="Please input" /> -->
                <input v-model="form.user_sn" type="numeber" :placeholder="$t('login.accountTips')" />
            </div>
            <div class="items">
                <!-- <el-input v-model="input" placeholder="Please input" /> -->
                <input v-model="form.password" type="password" :placeholder="$t('login.pwdTips')" />
            </div>
        </div>
        <template #footer>
            <div class="primaryBtn">
                <el-button @click="login" :loading="state.btnLoading">{{ $t('login.login') }}</el-button>
            </div>
        </template>
    </el-dialog>
</template>
<script>
    import indexJs from './indexJs'
    export default indexJs
</script>
<style lang="scss" scoped>
    @import '@/assets/styles/login.scss'
</style>