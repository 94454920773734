import { createI18n } from 'vue-i18n';
import zhtw from './zh-tw';
import zhcn from './zh-cn';
import en from './en';
import ja from './ja';

const messages = {
  zhcn,
  zhtw,
  en,
  ja
};
const language = (navigator.language || 'zhtw').toLocaleLowerCase(); // 这是获取浏览器的语言
const i18n = createI18n({
  legacy: !true,
  globalInjection: true,
  locale: localStorage.getItem('lang') || language.replace('-', '') || 'zhtw', // 首先从缓存里拿，没有的话就用浏览器语言，
  fallbackLocale: 'zhtw', // 设置备用语言
  messages,
});

export default i18n;