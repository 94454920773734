// lang / zh.ts
export default {
  nav: {
    about: 'About us',
    AccountOpenAct: 'Account Opening Event',
    AccountOpenGuide: 'Account Opening Tips',
    whiteList: 'Partner with Account Connect',
    contactUs: 'Contact Us',
    download: 'Download',
    accountOpenInfo: 'Account Opening Information',
    technologyPlatform: 'Technology Platform',
  },
  common: {
    email: 'Email',
    enter: 'Please enter...',
    pleaseSelect: 'Please select...',
    confirm: 'Confirm',
    enterContactInfo: 'Please enter contact information',
    seeMore: 'See more',
    currentLocation: 'Current location',
    back: 'Back',
    query: 'Query',
    chat: 'Chat',
    uploadFiles: 'Upload files',
    support: 'Support',
    fileTypes: 'File types',
    notSupported: 'This file type is not supported',
    limit: 'Limit quantity',
    canUploaded: 'pcs',
    reset: 'Reset'
  },
  about: {
    bannerTitle: 'Open an account using Global Business Express',
    userLogin: 'Partner User Login',
    progressQuery: 'Account Opening Progress Tracking',
    pairedSingle: 'Paired',
    institution: 'Registered professional institutions',
    traditionalBanks: 'Traditional banks',
    digitalBanks: 'Digital banks',
    residentInstitutions: 'Resident institutions',
    pairableBank: 'Pairable Bank',
  },
  accountOpenAct: {
    purpose: 'Purpose of event',
    latest: 'Coming soon',
    past: 'Past activities',
    actDetails: 'Activity Details',
    organizer: 'Organizer',
    activityTime: 'Activity time',
  },
  accountOpenGuide: {
    companyProcess: 'Business Account Opening Process',
    companyProcessOne: 'Place an order through Account Connect APP',
    companyProcessTwo: 'Partner received an order',
    companyProcessThree: 'Submit to bank',
    companyProcessFour: 'Appoinment for Witnes',
    companyProcessFive: 'Account Opening Successful',
    personalProcess: 'Personal account opening process',
    personalProcessOne: 'After the user confirms the process, it is crucial to prepare the bank filing information form in advance (this form is crucial)',
    personalProcessTwo: 'Submit relevant data to overseas banks',
    personalProcessThree: 'Activation funds required for users to remit foreign account opening',
    personalProcessFour: 'Bank assigned account, online banking ID password (for safety reasons, users can change their password as soon as possible after logging in), bank card',
    personalProcessFive: 'Enable online banking query function',
    amlWorkGuide: 'AML Work Guidelines'
  },
  download: {
    title: 'Global Business Express mobile end',
    queryDesc: 'Ordering services are more convenient and progress tracking is more convenient',
    version: 'Developer: WeGeNet International (HK) Limited. Version: 1.2.0(39) Privacy Policy Application Permissions',
    iosDownload: 'IOS Download',
    androidDownload: 'Android Download',
    accountOpenProgress: 'Account opening progress',
    companyList: 'Lists of companies',
    orderNo: 'Order number',
    orderTips: 'To view more order information, please open the app or download it to view it',
    companyTips: 'Please select a company',
  },
  institution: {
    bannerTitle: 'Hot recruitment by professional institutions',
    joinUs: 'Join us',
    settleIn: 'Being Partner Process',
    immediateEntry: 'Join now',
    joinListOne: 'Customer demand oriented docking',
    joinListTwo: 'Prioritize the use of core competencies',
    joinListThree: 'Strategic investment in core partners',
    joinListFour: 'Exclusive support for service landing',
    processListOne: 'Fill in the application form',
    processListTwo: 'Company Information Review',
    processListThree: 'Interview to understand',
    processListFour: 'Contract signing',
    processListFive: 'Success join our platform',
    processListOneDesc: 'Submit company documents to register',
    processListTwoDesc: 'Company information review (within 7 working days)',
    processListThreeDesc: 'Understand through face-to-face/video conference',
    processListFourDesc: 'Sign a joining contract to clarify the cooperation methods, rights and obligations of both parties',
    processListFiveDesc: 'Get start with Account Connect',
  },
  contact: {
    tips: 'If you have any questions, please contact us and leave your contact information. We will reply as soon as possible.',
    contactTips: '(The only official contact method, without Facebook, WeChat, or other social media contact methods)',
    support: 'Customer Support',
    supportTips: 'We are always available to assist you! If you have any inquiries, please email to',
    supportContact: 'customercare@wegenet.com',
    query: 'General Query',
    queryTips: 'For general inquiries, including cooperation opportunities, please fill out the form on this page.',
    queryContact: 'WhatsApp：+852 5122 5622',
    companyAddr: 'Company Address',
    addr: 'Address',
    name: 'Name',
    phoneNum: 'Contact no',
    companyName: 'Corporate name',
    position: 'Position',
    subject: 'Topic',
    remark: 'Leave a message',
    submit: 'Submit to',
  },
  login: {
    login: 'Log on',
    logOut: 'Log out of login',
    loggedAccount: 'Logged in account',
    accountLogin: 'Account password login',
    accountTips: 'Please enter an account',
    pwdTips: 'Please enter the password',
  },
  rpData: {
    company: 'Company',
    person: 'Individual',
    title: 'RP Registration Form',
    desc: 'Please fill in the information truthfully for platform review',
    basicInfo: 'Basic Information',
    settlementType: 'Settlement Type',
    settlementTypePlaceholder: 'Please select a type',
    supportingHKCompanies: 'Currently only supports Hong Kong company registration',
    companyNameEn: 'Your company English name',
    companyNameEnPlaceholder: 'Please enter your company English name',
    companyNameCn: 'Your company Chinese name',
    companyNameCnPlaceholder: 'Please enter your company Chinese name',
    companyWebsite: 'Your company website',
    companyWebsitePlaceholder: 'Please enter your company website',
    fax: 'Fax',
    faxPlaceholder: 'Please enter fax',
    contactNameEn: 'Contact person English name',
    contactNameEnPlaceholder: 'Please enter contact person English name',
    contactNameCn: 'Contact person Chinese name',
    contactNameCnPlaceholder: 'Please enter contact person Chinese name',
    phoneNum: 'Contact phone number',
    phoneNumPlaceholder: 'Please enter contact phone number',
    phoneNumAreaCodePlaceholder: 'Please select the contact phone area code',
    email: 'Email address',
    emailPlaceholder: 'Please enter email address',
    addr: 'Your company address',
    addrPlaceholder: 'Please fill in the complete address',
    roomNo: 'Floor/Room number',
    street: 'Street name',
    areaName: 'Area name',
    countryRegion: 'Country/Region',
    certificateReg: 'Company registration certificate',
    businessRegCertificate: 'Business registration certificate',
    AnnualDeclarationForm: 'Annual declaration form (latest year)',
    articlesAssociation: 'Company articles of association'
  }
};