import { ElMessage } from 'element-plus'
import { reactive } from "vue"
import { useI18n } from 'vue-i18n'
import user from '@/api/user'
const apiUser = new user()

export default{
    props: {
    },
    setup(props, {emit}){
        const { t } = useI18n()
        function closeDialog(){
            emit('close');
        }
        let state = reactive({
            btnLoading: false,
            userInfo: null
        })
        let form = reactive({
            user_sn: '',
            password: ''
        })
        let login =() => {
            if(!form.user_sn){
                ElMessage.error(t('login.accountTips'))
                return
            }
            if(!form.password){
                ElMessage.error(t('login.pwdTips'))
                return
            }
            let params = {...form}
            params.user_sn = 'WEGE-' + params.user_sn
            state.btnLoading = true
            apiUser.login(params).then((res) => {
                let { data, code, msg } = res.data
                if(code == 0){
                    sessionStorage.setItem("pcUserInfo", JSON.stringify(data));
                    state.userInfo = data
                    ElMessage.success(msg)
                    closeDialog()
                }else{
                    ElMessage.error(msg)
                }
                state.btnLoading = false
            }).catch((e) => {
                // ElMessage.error(e.message)
            });
        }
        return{
            dialogVisible: true,
            state,
            form,
            closeDialog,
            login
        }
    }
}