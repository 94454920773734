<template>
    <div :class="['header', white ? 'white' : '']">
        <div class="headerMain clearfix">
            <img v-if="white" src="@/assets/images/logo/logoGray.png" />
            <img v-else src="@/assets/images/logo/logo.png" />
            <el-menu
                :default-active="navIndex"
                mode="horizontal"
                @select="handleSelect"
                class="nav"
            >
                <template v-for="items in state.navList">
                    <el-menu-item class="items" :index="items.index" v-if="!items.children" @click="navClick(items)">
                        {{ items.name }}
                    </el-menu-item>
                    <el-sub-menu class="items" :index="items.index" v-else>
                        <template #title>{{ items.name }}</template>
                        <el-menu-item v-for="item in items.children" :index="item.index" @click="navClick(item)">
                            {{ item.name }}
                        </el-menu-item>
                    </el-sub-menu>
                </template>
                <el-dropdown @command="handleCommand">
                    <span class="el-dropdown-link">
                        {{ state.lang == 'zhcn' ? '中文简体' : state.lang == 'en' ? 'English' : state.lang == 'ja' ? '日本語' : '繁體中文' }}
                        <el-icon class="el-icon--right"><ArrowDown /></el-icon>
                    </span>
                    <template #dropdown>
                    <el-dropdown-menu>
                        <el-dropdown-item :style="state.lang == 'zhtw' ? 'color: #FF9600' : ''" command="zhtw">繁體中文</el-dropdown-item>
                        <el-dropdown-item :style="state.lang == 'zhcn' ? 'color: #FF9600' : ''" command="zhcn">中文简体</el-dropdown-item>
                        <el-dropdown-item :style="state.lang == 'en' ? 'color: #FF9600' : ''" command="en">English</el-dropdown-item>
                        <el-dropdown-item :style="state.lang == 'ja' ? 'color: #FF9600' : ''" command="ja">日本語</el-dropdown-item>
                    </el-dropdown-menu>
                    </template>
                </el-dropdown>
            </el-menu>
        </div>
        <div class="menuIcon">
            <el-icon :color="white ? '#333' : '#fff'" size="30" @click="menuClick"><Menu /></el-icon>
        </div>
    </div>
    <div class="leftMenu">
        <el-drawer v-model="state.drawerShow" size="70%" direction="ltr" title="I am the title" :with-header="false">
            <img class="logo" src="@/assets/images/logo/logoGray.png" />
            <!-- <div class="items" v-for="(items, index) in state.nav">{{ items.name }}</div>
            <div class="items" v-for="(items, index) in state.nav">{{ items.name }}</div> -->
            <el-menu
                :default-active="navIndex"
                class="el-menu-vertical-demo"
                @open="handleOpen"
                @close="handleClose"
            >
                <!-- <el-menu-item v-for="(items, index) in state.nav" :index="index" @click="navClick(items, index, 'menu')">
                    <span>{{ items.name }}</span>
                </el-menu-item> -->
                <template v-for="items in state.navList">
                    <el-menu-item class="items" :index="items.index" v-if="!items.children" @click="navClick(items)">
                        {{ items.name }}
                    </el-menu-item>
                    <el-sub-menu class="items" :index="items.index" v-else>
                        <template #title>{{ items.name }}</template>
                        <el-menu-item v-for="item in items.children" :index="item.index" @click="navClick(item)">
                            {{ item.name }}
                        </el-menu-item>
                    </el-sub-menu>
                </template>
                <el-sub-menu index="10">
                    <template #title>
                        <span>{{ state.lang == 'zhcn' ? '中文简体' : state.lang == 'en' ? 'English' : state.lang == 'ja' ? '日本語' : '繁體中文' }}</span>
                    </template>
                    <el-menu-item-group title="">
                        <el-menu-item index="1-1" @click="handleCommand('zhtw', 'menu')">繁體中文</el-menu-item>
                        <el-menu-item index="1-2" @click="handleCommand('zhcn', 'menu')">中文简体</el-menu-item>
                        <el-menu-item index="1-3" @click="handleCommand('en', 'menu')">English</el-menu-item>
                        <el-menu-item index="1-4" @click="handleCommand('ja', 'menu')">日本語</el-menu-item>
                    </el-menu-item-group>
                </el-sub-menu>
                
            </el-menu>
        </el-drawer>
    </div>
</template>
<script>
    import indexJs from './indexJs'
    export default indexJs
</script>
<style scoped lang="scss">
    @import '@/assets/styles/header.scss';
</style>