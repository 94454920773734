<template>
  <RouterView></RouterView>
</template>

<script>
import { onMounted } from 'vue'

export default {
  name: 'App',
  components: {
  },
  setup(){
    let setZoom = () => {
      document.body.style.zoom = '80%';
    }
    // setZoom()
    onMounted(() => {
      document.body.style.setProperty('--el-color-primary', '#FF9600');
      document.body.style.setProperty('--el-color-primary-light-9', 'rgba(255, 150, 0, 0.1)');
      document.body.style.setProperty('--el-color-primary-light-3', 'rgba(255, 150, 0, 0.9)');
    })
    return{
      setZoom
    }
  }
}
</script>

<style>
body{
  margin: 0;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #333;
  font-size: 14px;
}
</style>
