import { Axios } from '@/utils/request'
export default class user extends Axios {
  login(data) {
    return this.request({
      method: "POST",
      url: 'api/PcUser/login',
      data
    })
  }
  settled(data) {
    return this.request({
      method: "POST",
      url: 'admin/RpUser/reg',
      data
    })
  }
}