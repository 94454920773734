// lang / zh.ts
export default {
  nav: {
    about: '关于我们',
    AccountOpenAct: '开户活动',
    AccountOpenGuide: '开户指南',
    whiteList: '合作伙伴入驻',
    contactUs: '联络我们',
    download: '下载中心',
    accountOpenInfo: '开户资讯',
    technologyPlatform: '科技平台',
  },
  common: {
    email: '电邮',
    enter: '请输入...',
    pleaseSelect: '请选择...',
    confirm: '确认',
    enterContactInfo: '请输入联络方式',
    seeMore: '查看更多',
    currentLocation: '当前位置',
    back: '返回',
    query: '查询',
    chat: '会话',
    uploadFiles: '上传文件',
    support: '支持',
    fileTypes: '文件类型',
    notSupported: '不支持该文件类型',
    limit: '限制数量',
    canUploaded: '个',
    reset: '重置'
  },
  about: {
    bannerTitle: '开户就用环球商务通',
    userLogin: '专业机构用户登录',
    progressQuery: '开户进度查询',
    pairedSingle: '已配对（单）',
    institution: '注册专业机构（家）',
    traditionalBanks: '传统银行（家）',
    digitalBanks: '数码银行（家）',
    residentInstitutions: '入驻机构',
    pairableBank: '可配对银行（家）',
  },
  accountOpenAct: {
    purpose: '活动宗旨',
    latest: '最新活动',
    past: '过往活动',
    actDetails: '活动详情',
    organizer: '举办方',
    activityTime: '活动时间',
  },
  accountOpenGuide: {
    companyProcess: '企业开户流程',
    companyProcessOne: '合作洽谈',
    companyProcessTwo: '专家预审',
    companyProcessThree: '银行复审',
    companyProcessFour: '预约开户',
    companyProcessFive: '开户成功',
    personalProcess: '个人开户流程',
    personalProcessOne: '用户确定办理之后，提前准备银行备案信息表 (此表至关重要)',
    personalProcessTwo: '将有关资料提交到境外银行',
    personalProcessThree: '用户汇入外国开户所需缴活款',
    personalProcessFour: '银行分派账号、网络银行ID密码 (为了保险起见用户登录后最短时间 自行修改密码)，银行卡',
    personalProcessFive: '开通网上银行查询功能',
    amlWorkGuide: 'AML工作指南'
  },
  download: {
    title: '环球商务通手机端',
    queryDesc: '下单服务更便捷，查询进度更方便',
    version: '开发者：WeGeNet International (HK) Limited 版本：1.2.0(39) 隐私政策 应用权限',
    iosDownload: 'IOS下载',
    androidDownload: 'Android下载',
    accountOpenProgress: '开户进度',
    companyList: '公司列表',
    orderNo: '订单编号',
    orderTips: '若要查看更多的订单信息，请打开APP或下载APP进行查看',
    companyTips: '请选择公司',
  },
  institution: {
    bannerTitle: '专业机构火热招募中',
    joinUs: '加入我们',
    settleIn: '入驻流程',
    immediateEntry: '立即入驻',
    joinListOne: '客户需求 定向对接',
    joinListTwo: '核心能力 优先使用',
    joinListThree: '核心伙伴 战略投资',
    joinListFour: '服务落地 专属支持',
    processListOne: '提交入驻资料',
    processListTwo: '资质审核',
    processListThree: '商务签约完成',
    processListFour: '完善平台机构信息',
    processListFive: '服务发布上线',
    processListOneDesc: '提交机构资料 用于注册专业机构账号',
    processListTwoDesc: '类目运营审核 （7个工作日內）',
    processListThreeDesc: '线下合同签约并 缴纳保证金',
    processListFourDesc: '完善平台机构信息，添加 客户信息资料',
    processListFiveDesc: '服务正式上线 承接客户服务',
  },
  contact: {
    tips: '如有任何问题，请与我们联系，请留下您的联系方式，我们会尽快回复。',
    contactTips: '（唯一的官方联络方式，沒有Facebook、微信和其他社交媒体联络方式）',
    support: '客户支援',
    supportTips: '我们随时为您提供帮助！如有任何查询，请电邮至',
    supportContact: 'customercare@wegenet.com',
    query: '一般查询',
    queryTips: '对于一般查詢，包括合作机会，请填写此页面上的表格。',
    queryContact: 'WhatsApp：+852 5122 5622',
    companyAddr: '公司地址',
    addr: '地址',
    name: '名称',
    phoneNum: '联络号码',
    companyName: '公司名称',
    position: '职位',
    subject: '题目',
    remark: '留言',
    submit: '提交',
  },
  login: {
    login: '登录',
    logOut: '退出登錄',
    loggedAccount: '已登录账号',
    accountLogin: '账号密码登录',
    accountTips: '请输入账号',
    pwdTips: '请输入密码',
  },
  rpData:{
    company: '公司',
    person: '个人',
    title: 'RP入驻资料填写',
    desc: '请如实填写信息，以便平台审核',
    basicInfo: '基本信息',
    settlementType: '入驻类型',
    settlementTypePlaceholder: '请选择类型',
    supportingHKCompanies: '目前仅支持香港公司入驻',
    companyNameEn: '贵公司英文名称',
    companyNameEnPlaceholder: '请输入贵公司英文名称',
    companyNameCn: '贵公司中文名称',
    companyNameCnPlaceholder: '请输入贵公司中文名称',
    companyWebsite: '贵公司网址',
    companyWebsitePlaceholder: '请输入贵公司网址',
    fax: '传真',
    faxPlaceholder: '请输入传真',
    contactNameEn: '联络人英文名称',
    contactNameEnPlaceholder: '请输入联络人英文名称',
    contactNameCn: '联络人中文名称',
    contactNameCnPlaceholder: '请输入联络人中文名称',
    phoneNum: '联络电话',
    phoneNumPlaceholder: '请输入联络电话',
    phoneNumAreaCodePlaceholder: '请选择联络电话区号',
    email: '电子邮箱',
    emailPlaceholder: '请输入电子邮箱',
    addr: '贵公司地址',
    addrPlaceholder: '请填写完整地址',
    roomNo: '楼层/房间号',
    street: '街道名称',
    areaName: '区域名称',
    countryRegion: '国家/地区',
    certificateReg: '公司注册证书',
    businessRegCertificate: '商业登记证书',
    AnnualDeclarationForm: '周年申报表(最新一年)',
    articlesAssociation: '公司章程',
  }
};