import { createApp } from 'vue'
import App from './App.vue'
import router from './router'

import i18n from './lang'

import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'

// import VueAwesomeSwiper from 'vue-awesome-swiper'
import 'swiper/css'

import '@/assets/styles/common.scss'

const app = createApp(App)

import Header from '@/components/Header' // 头部
import Footer from '@/components/Footer' // 底部
import NoData from '@/components/NoData' // 暂无数据
import Login from '@/components/Login' // 登录弹窗


app.use(router).use(ElementPlus).use(i18n).component('Header', Header).component('Footer', Footer).component('NoData', NoData).component('Login', Login).mount('#app')
