import { createWebHistory, createRouter, createWebHashHistory } from "vue-router";

const routes = [
    {
      path: '/',
      name: 'About',
      component: () => import('@/views/about/about')
    }, {
      path: '/accountOpenAct',
      name: 'AccountOpenAct',
      component: () => import('@/views/accountOpenAct/accountOpenAct')
    }, {
      path: '/accountOpenAct/details',
      name: 'AccountOpenActDetails',
      component: () => import('@/views/accountOpenAct/details/details')
    }, {
      path: '/accountOpenGuide',
      name: 'AccountOpenGuide',
      component: () => import('@/views/accountOpenGuide/accountOpenGuide')
    }, {
      path: '/download',
      name: 'Download',
      component: () => import('@/views/download/download')
    }, {
      path: '/institution',
      name: 'Institution',
      component: () => import('@/views/institution/institution')
    }, {
      path: '/contactUs',
      name: 'ContactUs',
      component: () => import('@/views/contactUs/contactUs')
    }, {
      path: '/technologyPlatform',
      name: 'TechnologyPlatform',
      component: () => import('@/views/technologyPlatform/technologyPlatform')
    }, {
      path: '/rpSettlement',
      name: 'RpSettlement',
      component: () => import('@/views/rpSettlement/rpSettlement')
    }
  ]
  
  const router = createRouter({
    history: createWebHashHistory(),
    // history: createWebHistory(),
    routes,
  })
  
  export default router