import { Axios } from '@/utils/request'
export default class aboutTw extends Axios {
    cmssortscodeBannerTw() { //关于我们首页图
        return this.request({
            method: "get",
            url: 'api.php/cms/sort/scode/16/acode/hk',
        })
    }
    aboutacodeTextTw() { //关于我们
        return this.request({
            method: "get",
            url: 'api.php/about/16/acode/hk',
        })
    }
    linkgid() { //入住机构
        return this.request({
            method: "get",
            url: 'api.php/cms/link/gid/1/num/10',
        })
    }
    cmssortscodeTw() { //开户活动banner
        return this.request({
            method: "get",
            url: 'api.php/cms/sort/scode/17/acode/hk',
        })
    }
    aboutTextTw() { //活动宗旨
        return this.request({
            method: "get",
            url: 'api.php/about/18/acode/hk',
        })
    }
    // -最新活動-過往活動（get提交）
    listpageacodeTw() {
        return this.request({
            method: "get",
            url: 'api.php/list/19/page/1/num/3/acode/hk',
        })
    }
    //过往活动详情
    contentacodeTw(data) {
        return this.request({
            method: "get",
            url: `api.php/content/${data}/acode/hk`,
        })
    }
    //開戶指南banner
    sortscodeacodebannerTw() {
        return this.request({
            method: "get",
            url: 'api.php/cms/sort/scode/20/acode/hk',
        })
    }
    //開戶指南文本
    aboutacodeTw() {
        return this.request({
            method: "get",
            url: 'api.php/about/21/acode/hk',
        })
    }
    //AML工作指南
    aboutacodeAmlTw() {
        return this.request({
            method: "get",
            url: 'api.php/about/22/acode/hk',
        })
    }
    //机构白名单
    cmsscodeacodehkTw() {
        return this.request({
            method: "get",
            url: 'api.php/cms/sort/scode/23/acode/hk',
        })
    }
    //加入我们
    aboutacodehkTw() {
        return this.request({
            method: "get",
            url: 'api.php/about/23/acode/hk',
        })
    }
    //联络我们banner
    cmsbannerTw() {
        return this.request({
            method: "get",
            url: 'api.php/cms/sort/scode/24/acode/hk',
        })
    }
    //联络我们文本
    aboutacodeTextTW() {
        return this.request({
            method: "get",
            url: 'api.php/about/24/acode/hk',
        })
    }
    //在线留言
    cmsaddmsgacodeTW(params) {
        return this.request({
            method: "post",
            url: 'api.php/cms/addmsg/acode/hk',
            data: params
        })
    }
}