import { reactive, computed } from "vue"
import { useI18n } from 'vue-i18n'
export default{
    setup(){
        const { t } = useI18n()
        let navClick = (items) =>{
            if(items.url){
                this.$router.push(items.url)
            }
        }
        let state = reactive({
            nav: []
        })
        state.nav = computed(() => {
            return [
                {name: t('nav.about'), url: '/'},
                {name: t('nav.AccountOpenAct'), url: '/accountOpenAct'},
                {name: t('nav.AccountOpenGuide'), url: '/accountOpenGuide'},
                {name: t('nav.whiteList'), url: '/institution'},
                {name: t('nav.contactUs'), url: '/contactUs'},
                {name: t('nav.download'), url: '/download'},
            ]
        })
        return{
            state,
            navClick
        }
    }
}