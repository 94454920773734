// lang / zh.ts
export default {
  nav: {
    about: '關於我們',
    AccountOpenAct: '開戶活動',
    AccountOpenGuide: '開戶指南',
    whiteList: '合作夥伴入駐',
    contactUs: '聯絡我們',
    download: '下載中心',
    accountOpenInfo: '開戶資訊',
    technologyPlatform: '科技平臺',
  },
  common: {
    email: '電郵',
    enter: '請輸入...',
    pleaseSelect: '請選擇...',
    confirm: '確認',
    enterContactInfo: '請輸入聯絡方式',
    seeMore: '查看更多',
    currentLocation: '當前位置',
    back: '返回',
    query: '查詢',
    chat: '會話',
    uploadFiles: '上傳文件',
    support: '支持',
    fileTypes: '文件類型',
    notSupported: '不支持該文件類型',
    limit: '限制數量',
    canUploaded: '個',
    reset: '重設'
  },
  about: {
    bannerTitle: '開戶就用環球商務通',
    userLogin: '專業機構用戶登錄',
    progressQuery: '開戶進度登錄',
    pairedSingle: '已配對（單）',
    institution: '注冊專業機構（傢）',
    traditionalBanks: '傳統銀行（傢）',
    digitalBanks: '數碼銀行（傢）',
    residentInstitutions: '入駐機構',
    pairableBank: '可配對銀行（傢）',
  },
  accountOpenAct: {
    purpose: '活動宗旨',
    latest: '最新活動',
    past: '過往活動',
    actDetails: '活動詳情',
    organizer: '擧辦方',
    activityTime: '活動時間',
  },
  accountOpenGuide: {
    companyProcess: '企業開戶流程',
    companyProcessOne: '合作洽談',
    companyProcessTwo: '專家預審',
    companyProcessThree: '銀行復審',
    companyProcessFour: '預約開戶',
    companyProcessFive: '開戶成功',
    personalProcess: '個人開戶流程',
    personalProcessOne: '用戶確定辦理之後， 提前准備銀行備案信息表 (此表至關重要)',
    personalProcessTwo: '將有關資料提交到境外銀行',
    personalProcessThree: '用戶匯入外國開戶所需激活款',
    personalProcessFour: '銀行分派賬號、網絡銀行ID密碼 (爲了保險起見用戶登錄後最短時間 自行更改密碼) 、銀行卡',
    personalProcessFive: '開通網上銀行查詢功能',
    amlWorkGuide: 'AML工作指南'
  },
  download: {
    title: '環球商務通手機端',
    queryDesc: '下單服務更便捷，查詢進度更方便',
    version: '開發者：WeGeNet International (HK) Limited 版本：1.2.0(39) 隱私政策 應用權限',
    iosDownload: 'IOS下載',
    androidDownload: 'Android下載',
    accountOpenProgress: '開戶進度',
    companyList: '公司列表',
    orderNo: '訂單編號',
    orderTips: '若要查看更多的訂單信息，請打開APP或下載APP進行查看',
    companyTips: '请选择公司',
  },
  institution: {
    bannerTitle: '專業機構火熱招募中',
    joinUs: '加入我們',
    settleIn: '入駐流程',
    immediateEntry: '立即入駐',
    joinListOne: '客戶需求 定向對接',
    joinListTwo: '核心能力 優先使用',
    joinListThree: '核心夥伴 戰略投資',
    joinListFour: '服務落地 專屬支持',
    processListOne: '提交入駐資料',
    processListTwo: '資質審核',
    processListThree: '商務簽約完成',
    processListFour: '完善平台機構信息',
    processListFive: '服務發佈上線',
    processListOneDesc: '提交機構資料 用於註冊專業機構賬號',
    processListTwoDesc: '類目運營審核 （7個工作日內）',
    processListThreeDesc: '線下合同簽約並 繳納保證金',
    processListFourDesc: '完善平台機構信息，添加 客戶信息資料',
    processListFiveDesc: '服務正式上線 承接客戶服務',
  },
  contact: {
    tips: '如有任何問題，請與我們聯繫，請留下您的聯繫方式，我們會盡快回復。',
    contactTips: '（唯一的官方聯絡方式，沒有Facebook、微信和其他社交媒體聯絡方式）',
    support: '客戶支援',
    supportTips: '我們隨時為您提供幫助！如有任何查詢，請電郵至',
    supportContact: 'customercare@wegenet.com',
    query: '一般查詢',
    queryTips: '對於一般查詢，包括合作機會，請填寫此頁面上的表格。',
    queryContact: 'WhatsApp：+852 5122 5622',
    companyAddr: '公司地址',
    addr: '地址',
    name: '名稱',
    phoneNum: '聯絡號碼',
    companyName: '公司名稱',
    position: '職位',
    subject: '題目',
    remark: '留言',
    submit: '提交',
  },
  login: {
    login: '登錄',
    logOut: '退出登录',
    loggedAccount: '已登录账号',
    accountLogin: '賬號密碼登錄',
    accountTips: '請輸入賬號',
    pwdTips: '請輸入密碼',
  },
  rpData:{
    company: '公司',
    person: '個人',
    title: 'RP入驻資料填寫',
    desc: '請如實填寫信息，以便平台審核',
    basicInfo: '基本信息',
    settlementType: '入驻類型',
    settlementTypePlaceholder: '請選擇類型',
    supportingHKCompanies: '目前僅支持香港公司入駐',
    companyNameEn: '貴公司英文名稱',
    companyNameEnPlaceholder: '請輸入貴公司英文名稱',
    companyNameCn: '貴公司中文名稱',
    companyNameCnPlaceholder: '請輸入貴公司中文名稱',
    companyWebsite: '貴公司網址',
    companyWebsitePlaceholder: '請輸入貴公司網址',
    fax: '傳真',
    faxPlaceholder: '請輸入傳真',
    contactNameEn: '聯絡人英文名稱',
    contactNameEnPlaceholder: '請輸入聯絡人英文名稱',
    contactNameCn: '聯絡人中文名稱',
    contactNameCnPlaceholder: '請輸入聯絡人中文名稱',
    phoneNum: '聯絡電話',
    phoneNumPlaceholder: '請輸入聯絡電話',
    phoneNumAreaCodePlaceholder: '請選擇聯絡電話區號',
    email: '電子郵箱',
    emailPlaceholder: '請輸入電子郵箱',
    addr: '貴公司地址',
    addrPlaceholder: '請填寫完整地址',
    roomNo: '樓層/房間號',
    street: '街道名稱',
    areaName: '區域名稱',
    countryRegion: '國家/地區',
    certificateReg: '公司註冊證書',
    businessRegCertificate: '商業登記證書',
    AnnualDeclarationForm: '周年申報表(最新一年)',
    articlesAssociation: '公司章程',
  }
};