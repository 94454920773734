<template>
    <div class="footer">
        <div class="main">
            <div class="items clearfix">
                <img src="@/assets/images/logo/logo.png" />
            </div>
            <div class="items">
                <div v-for="items in state.nav" @click="navClick(items)">{{ items.name }}</div>
            </div>
            <div class="items">
                <div class="footerTitle">{{ $t('nav.about') }}</div>
                <div>{{ $t('common.email') }}：customercare@wegenet.com</div>
                <div>WhatsApp：+852 5122 5622</div>
            </div>
            <!-- <div class="items">
                <div>{{ $t('common.enterContactInfo') }}</div>
                <div class="input">
                    <input :placeholder="$t('common.enter')" />
                </div>
                <el-button>{{ $t('common.confirm') }}</el-button>
            </div> -->
        </div>
        <!-- <div class="bottom">© 2023 by Account Connect lnternational limited</div> -->
        <div class="bottom">WeGeNet International (HK) Limited</div>
    </div>
</template>
<script>
    import indexJs from './indexJs'
    export default indexJs
</script>
<style lang="scss" scoped>
    @import '@/assets/styles/footer.scss';
</style>