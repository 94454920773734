import { reactive, computed, ref } from "vue"
import { useI18n } from 'vue-i18n'
import { ArrowDown, Menu } from '@element-plus/icons-vue'
import { znrequest, Twrequest,enrequest,Jarequest } from '@/lang/langrequest'

export default {
    props: {
        navIndex: 0,
        white: false
    },
    components: {
        ArrowDown,
        Menu
    },
    setup(props, context) {
        const { t, locale } = useI18n()
        let state = reactive({
            lang: 'zhtw',
            nav: [],
            drawerShow: false
        })
        // 侧边菜单点击
        const menuClick = () => { 
            state.drawerShow = !state.drawerShow
        }
        function navClick(items, menu) {
            if(menu && menu == 'menu'){ // 左侧菜单点击
                state.drawerShow = false
            }
            if (items.url) {
                this.$router.push(items.url)
            }
        }
        state.lang = localStorage.getItem('lang') || 'zhtw'
        locale.value = localStorage.getItem('lang') || 'zhtw'
        let handleCommand = (e, menu) => {
            state.lang = e
            locale.value = e
            localStorage.setItem('lang', e)
            context.emit('languageClick', e)
            if(menu && menu == 'menu'){ // 左侧菜单点击
                menuClick()
            }
            
            InSWZh()
        }
        let handleMenu = (e) => {

        }
        function InSWZh() {
            switch (localStorage.getItem('lang')) {
                case "zhcn":
                    znrequest()
                    break;
                case "ja":
                    Jarequest()
                    break;
                case "en":
                    enrequest()
                    break;
                default:
                    Twrequest()
            }
        }
        InSWZh()
        state.nav = computed(() => {
            return [
                { name: t('nav.about'), url: '/' },
                { name: t('nav.AccountOpenAct'), url: '/accountOpenAct' },
                { name: t('nav.AccountOpenGuide'), url: '/accountOpenGuide' },
                { name: t('nav.whiteList'), url: '/institution' },
                { name: t('nav.contactUs'), url: '/contactUs' },
                { name: t('nav.download'), url: '/download' },
            ]
        })
        state.navList = computed(() => {
            return [
                {
                    name: t('nav.about'), 
                    url: '/',
                    index: '1',
                    children: null
                }, { 
                    name: t('nav.accountOpenInfo'), 
                    url: '',
                    index: '2',
                    children: [
                        { name: t('nav.AccountOpenAct'), url: '/accountOpenAct', index: '2-1' },
                        { name: t('nav.AccountOpenGuide'), url: '/accountOpenGuide', index: '2-2' }
                    ]
                }, 
                // { 
                //     name: t('nav.technologyPlatform'), 
                //     url: '/technologyPlatform',
                //     index: '3',
                //     children: null
                // }, 
                {
                    name: t('nav.whiteList'), 
                    url: '/institution',
                    index: '4',
                    children: null
                }, {
                    name: t('nav.contactUs'), 
                    url: '/contactUs',
                    index: '5',
                    children: null
                }, {
                    name: t('nav.download'), 
                    url: '/download',
                    index: '6',
                    children: null
                }
            ]
        })
        return {
            state,
            navClick,
            handleCommand,
            menuClick
        }
    },
}