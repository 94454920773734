import axios from 'axios'
import Settings from './settings'
const requestUrl = Settings.requestUrl
const appRequestUrl = Settings.appRequestUrl
const adminRequestUrl = Settings.adminRequestUrl

//请求拦截
axios.interceptors.request.use((config) => {
    //请求之前重新拼装url 
    if (config.url == 'api/PcUser/getOrderList' || config.url == 'api/PcUser/getEnterpriseList' || config.url == 'api/PcUser/login') {
        // 开户易app接口地址
        config.url = appRequestUrl + config.url;
    }else if(config.url == 'admin/RpUser/upload' || config.url == 'admin/Common/getAllCountry' || config.url == 'admin/RpUser/reg'){
        // 开户易管理系统接口地址
        config.url = adminRequestUrl + config.url;
    }else{
        config.url = requestUrl + config.url;
    }

    return config;
});
axios.interceptors.response.use(res => {
    // 对响应数据做些什么
    return res
}, err => {
    // 对响应错误做些什么
    // console.log('err', err.response) // 修改后
    // console.log(err)
    return Promise.reject(err.response)
});
class Axios {
    request({ url, data = {}, method = "GET" }) {
        return new Promise((resolve, reject) => {
            this._request(url, resolve, reject, data, method);
        });
    }
    _request(url, resolve, reject, data = {}, method = "GET") {
        // document.getElementById('egg').before(url)
        let format = method.toLocaleLowerCase() === 'get' ? 'params' : 'data';
        if (sessionStorage.getItem("pcUserInfo")) {
            data.token = JSON.parse(sessionStorage.getItem("pcUserInfo")).pc_token
        }
        let headers = {
            'Content-Type': 'application/x-www-form-urlencoded'
        }
        axios({
            url: url,
            method: method,
            [format]: data,
            timeout: Settings.timeout, // 请求超时时间,
            headers: headers
        }).then(res => {
            // resolve(res);
            if (res.status == 200) {
                resolve(res);
                if (!res.data.success) {

                }
            }
        }).catch((err) => {
            reject(err);
        })
    }
}
export { Axios, requestUrl }
