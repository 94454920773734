import { Axios } from '@/utils/request'
export default class aboutja extends Axios {
  bannerMyJA() { //关于我们首页图
    return this.request({
      method: "get",
      url: 'api.php/cms/sort/scode/25/acode/ja',
    })
  }
  aboutMyJA() { //关于我们
    return this.request({
      method: "get",
      url: 'api.php/about/25/acode/ja',
    })
  }

  linkgid() { //入住机构
    return this.request({
      method: "get",
      url: 'api.php/cms/link/gid/1/num/10',
    })
  }
  cmssortImgJA() { //开户活动
    return this.request({
      method: "get",
      url: 'api.php/cms/sort/scode/26/acode/ja',
    })
  }
  aboutTextJA() { //活动宗旨
    return this.request({
      method: "get",
      url: 'api.php/about/27/acode/ja',
    })
  }
  // -最新活動-過往活動（get提交）
  pagelistnumJA() {
    return this.request({
      method: "get",
      url: 'api.php/list/28/page/1/num/3/acode/ja',
    })
  }

  //过往活动详情
  contentadisetJA(data) {
    return this.request({
      method: "get",
      url: `api.php/content/${data}/acode/ja`,
    })
  }
  //開戶指南banner
  sortscodeacodebannerJA() {
    return this.request({
      method: "get",
      url: 'api.php/cms/sort/scode/29/acode/ja',
    })
  }
  //開戶指南文本
  userTextJA() {
    return this.request({
      method: "get",
      url: 'api.php/about/30/acode/ja',
    })
  }
  //AML工作指南
  aboutAmlJA() {
    return this.request({
      method: "get",
      url: 'api.php/about/31/acode/ja',
    })
  }
  //机构白名单
  cmsscodeacodeJA() {
    return this.request({
      method: "get",
      url: 'api.php/cms/sort/scode/32/acode/ja',
    })
  }
  //加入我们
  aboutMYJA() {
    return this.request({
      method: "get",
      url: 'api.php/about/32/acode/ja',
    })
  }
  //联络我们banner
  cmsbannerJA() {
    return this.request({
      method: "get",
      url: 'api.php/cms/sort/scode/33/acode/ja',
    })
  }
  //联络我们form
  aboutacodeTextJA() {
    return this.request({
      method: "get",
      url: 'api.php/about/33/acode/ja',
    })
  }
  //在线留言
  cmsaddmsgaJA(data) {
    return this.request({
      method: "POST",
      url: 'api.php/cms/addmsg/acode/ja',
      data
    })
  }
}